
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import J1LNonLoggedInFooter from './NonLoggedInComponents/J1LNonLoggedInFooter.vue';
@Component({
  components: { BasicHelpDialog, J1LNonLoggedInFooter }
})
export default class extends Vue {
  $refs!: {
    HelpDeskDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop({ default: 0 }) margin!: number;
  /* Store Actions */
  /* Watchers */
  /* Data */
  footerOptions: any = [
    { Id: 1, Text: 'Templates', Readonly: false },
    { Id: 2, Text: 'Contact us', Readonly: false },
    { Id: 3, Text: 'Transparency', Readonly: false },
    { Id: 4, Text: 'Terms of Service', Readonly: false },
    { Id: 5, Text: '@ 2021 Just One Label, LLC', Readonly: true }
  ];
  public items: any = [
    { icon: '', text: 'Home', path: '/', routerName: 'Home' },
    {
      text: 'Label Types',
      isGroup: true,
      isExpanded: true,
      pages: [
        {
          icon: '',
          text: 'Custom Labels',
          path: '/custom-labels',
          routerName: 'CustomLabels'
        },
        {
          icon: '',
          text: 'Beverage & Drink Labels',
          path: '/custom-labels/beverage-and-drink-labels',
          routerName: 'BeverageAndDrinkLabels'
        },
        {
          icon: '',
          text: 'Cannabis Labels',
          path: '/custom-labels/cannabis-labels',
          routerName: 'CannabisLabels'
        },
        {
          icon: '',
          text: 'Discount Labels',
          path: '/custom-labels/discount-labels',
          routerName: 'DiscountLabels'
        },
        {
          icon: '',
          text: 'Skin And Beauty Labels',
          path: '/custom-labels/skin-beauty-labels',
          routerName: 'SkinAndBeautyLabels'
        },
        {
          icon: '',
          text: 'Food Labels',
          path: '/custom-labels/food-labels',
          routerName: 'FoodLabels'
        },
        {
          icon: '',
          text: 'Health & Wellness Labels',
          path: '/custom-labels/health-wellness-labels',
          routerName: 'HealthWellnessLabels'
        },
        {
          icon: '',
          text: 'Pet Product Labels',
          path: '/custom-labels/pet-product-labels',
          routerName: 'PetProductLabels'
        },
        {
          icon: '',
          text: 'Bath & Body Products',
          path: '/custom-labels/bath-body-products',
          routerName: 'BathBodyProducts'
        }
        // {
        //   icon: '',
        //   text: 'Custom Clothing Labels',
        //   path: '/custom-labels/custom-clothing-labels',
        //   routerName: 'CustomClothingLabels'
        // }
      ]
    },
    {
      icon: '',
      text: 'Contact Us',
      path: '/contact',
      routerName: 'Contact'
    },
    {
      icon: '',
      text: 'Blog',
      path: 'https://www.just1label.com/blog',
      routerName: ''
    }
  ];
  shouldOpenDrawer: boolean = false;
  /* Utility Functions */
  handleFooterActions(i: number) {
    switch (i) {
      case 1:
        this.$router.push({
          name: 'DieTemplates'
        });
        return;
      case 2:
        this.$router.push({
          name: 'Contact'
        });
        return;
      case 3:
        window.location.href = 'https://www.just1label.com/transparency';
        return;
      case 4:
        this.$router.push({
          name: 'Terms'
        });
        return;
    }
  }
  openHelpDesk() {
    this.$refs.HelpDeskDialog.openDialog();
  }
  goToLogin() {
    this.$router.push('Login');
  }
  goToSignup() {
    this.$router.push('Register');
  }
  goToHome() {
    this.$router.push({ name: 'Home' });
  }
  isOnPage(path: string) {
    return this.$route.path.toLowerCase() === path.toLowerCase();
  }
  isGroupOnPage(pages: any[]) {
    for (let i = 0; i < pages.length; i++) {
      if (this.isOnPage(pages[i].path)) return true;
    }
    return false;
  }
  groupLinkColor(group: any) {
    return this.isGroupOnPage(group.pages) ? 'black' : 'white';
  }
  linkColor(page: any) {
    return this.isOnPage(page.path) ? 'black !important' : 'white !important';
  }
  public goTo(item: {
    icon: string;
    text: string;
    path: string;
    routerName: string;
  }): void {
    if (!item.path) {
      // this.shouldOpenDrawer = false;
      return;
    }
    if (item.path.includes('www')) {
      location.replace(item.path);
    } else if (this.$route.path.toLocaleLowerCase() !== item.path.toLowerCase()) {
      this.$router.push({ name: item.routerName });
    } else {
      window.location.reload();
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    console.log('mounted');
  }
  /* Created */
  /* Emmited Functions */
}
