
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BugReport from '@/components/BugReport.vue';
@Component({
  components: { BugReport }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  footerOptions: any = [
    { Id: 1, Text: 'Templates', Readonly: false },
    { Id: 2, Text: 'Contact us', Readonly: false },
    { Id: 3, Text: 'Transparency', Readonly: false },
    { Id: 4, Text: 'Terms of Service', Readonly: false },
    { Id: 5, Text: `@ ${new Date().getFullYear()} Just One Label, LLC`, Readonly: true }
  ];
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  goToHome() {
    this.$router.push({ name: 'Home' });
  }
  handleFooterActions(option: string) {
    switch (option) {
      case 'Templates':
        return this.$router.push({ name: 'DieTemplates' });
      case 'Contact us':
        return this.$router.push({ name: 'Contact' });
      case 'Transparency':
        return window.open('https://transparency.just1label.com', '_blank').focus();
      case 'Terms of Service':
        return this.$router.push({ name: 'Terms' });
    }
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
